import algoliasearchApiInstance from "./api";

function enableAutoComplete(searchInputId, searchInputName) {
  const { autocomplete, getAlgoliaResults } = window[
    "@algolia/autocomplete-js"
  ];
  const searchClient = algoliasearchApiInstance.getClient();
  const boardIndexName = algoliasearchApiInstance.getBoardIndexName();
  const authorIndexName = algoliasearchApiInstance.getAuthorIndexName();
  const placeholders = [
    "かぐや様は告らせたい～天才たちの恋愛頭脳戦～",
    "正反対な君と僕",
    "怨み屋本舗WORST",
    "君のことが大大大大大好きな100人の彼女",
    "ちはやふる",
    "ダンス・ダンス・ダンスール",
    "ダンジョン飯",
    "ヴィンランド・サガ",
    "あずみ",
    "スキップとローファー",
    "あかね噺",
    "１日外出録ハンチョウ",
    "きのう何食べた？",
    "立ち飲みご令嬢",
    "ラクガキ～呪いの館～",
    "二月の勝者　―絶対合格の教室―",
    "ゴールデンカムイ",
    "外道の歌",
    "かけあうつきひ",
    "ゴミ溜めと中華鍋とあなた",
    "白竜HADOU",
    "バツハレ",
    "焼いてるふたり",
    "キミの甘さじゃときめかない",
    "彼岸島　４８日後…",
    "ひらやすみ",
    "レ･セルバン",
    "弱虫ペダル",
    "このマンガのヒロインは守崎あまねです。",
    "硬派銀次郎",
    "君は放課後インソムニア",
    "ノケモノたちの夜",
    "東京卍リベンジャーズ",
    "無二の一撃",
    "ブルーロック",
    "推しが武道館いってくれたら死ぬ",
    "半分姉弟",
    "ミナミの帝王",
    "都合のいい果て",
    "バキ道",
    "スナックバス江",
    "パリピ孔明",
    "チ。―地球の運動について―",
    "ダーウィン事変",
    "この会社に好きな人がいます",
    "JKハルは異世界で娼婦になった",
    "メダリスト",
    "きみとピコピコ",
    "誰か夢だと言ってくれ",
    "シビは寝ている",
    "Papa told me",
    "キラキラとギラギラ",
    "魔法少年マモル始まらない！",
    "【推しの子】",
    "女子校のこひー先生",
    "去勢転生",
    "クズメン百鬼夜行【合本版】",
    "道草寄子の食べ走り",
    "NARUTO―ナルト― モノクロ版",
    "日比谷さんの刈り上げ",
    "荒呼吸",
    "銀牙伝説ノア",
    "ジョジョの奇妙な冒険 第1部 カラー版",
    "よみがえる子猫たち",
    "古代戦士ハニワット",
    "GOLDEN SPIRAL",
    "兎が二匹",
    "追燈",
    "ラブコメクエスト",
    "おとりよせ王子　飯田好実",
    "早朝始発の殺風景",
    "サマータイムレンダ2026 未然事故物件",
    "九条の大罪",
    "山、田畑、時々レイヤーお姉さん",
    "プラタナスの実",
    "紫雲寺家の子供たち",
    "踊るミシン 2022Final",
    "海が見える大井町",
    "不沈アタッカー",
    "ツイステッド・シスターズ",
    "マイホームヒーロー",
    "ゾミア",
    "白山と三田さん",
    "どうせ、恋してしまうんだ。",
    "同人女アパート建ててみた",
    "カノジョも彼女",
    "ボレーボレー",
    "誰も知らんがな",
    "サラダ・ヴァイキング",
    "20XX年レベルアップ災害～神から授かりし新たなる力～",
    "ラーメン赤猫",
    "艦隊のシェフ",
    "新グッドジョブ",
    "税金で買った本",
    "リエゾン",
    "イリオス",
    "対岸のメル―幽冥探偵調査ファイル―",
    "東大くんと元ギャルさん～格差婚ロワイヤル～",
    "ゆりあ先生の赤い糸",
    "僕とロボコ",
    "キャラメル シナモン ポップコーン",
    "NINE PEAKS",
    "踊れ獅子堂賢",
    "吉原プラトニック",
    "黒岩メダカに私の可愛いが通じない",
    "女優めし",
    "マリッジトキシン",
    "ALIENS AREA",
    "猛き黄金の国 二宮金次郎",
    "コウノドリ 新型コロナウイルス編",
    "法廷遊戯",
    "she is beautiful",
    "レッドブルー",
    "エアドラ",
    "Ｗスティール",
    "ヘブンの天秤",
    "出禁のモグラ",
    "ジカジョの惑星",
    "四季崎姉妹はあばかれたい",
    "かまくらBAKE猫倶楽部",
    "月読くんの禁断お夜食",
    "イゴールの島",
    "天狗の台所",
    "相続探偵",
    "鬼ゴロシ",
    "ダンプ・ザ・ヒール",
    "またのお越しを",
    "はじめラブコメ オガベベ",
    "東村アキコ",
    "いちのへ瑠美",
    "雨宮ケント",
    "向井沙子",
    "萩尾望都",
    "諫山創",
    "嶽辻円盤",
    "前野温泉",
    "本田真吾",
    "藤本タツキ",
    "永井豪",
    "藤田律",
    "合田蛍冬",
    "吉良はなまる",
    "木村龍介",
    "木本ミキ",
    "ふゆ",
    "よしながふみ",
    "中村光",
    "河部真道",
    "内藤光太郎",
    "三石メガネ",
    "沙村広明",
    "井上コウセイ",
    "安野モヨコ",
    "魚豊",
    "野田サトル",
    "和山やま",
    "さいとう・たかを賞",
    "俺マン",
    "ananマンガ大賞",
    "星雲賞 コミック部門",
    "講談社漫画賞",
    "全国書店員が選んだおすすめコミック",
    "アングレーム国際漫画祭",
    "小学館漫画賞",
    "日本SF大賞",
    "Sense of Gender賞",
    "コミックナタリー大賞",
    "このマンガがすごい！",
    "ブクログ大賞(ノミネート)",
    "手塚治虫文化賞",
    "マンガ大賞",
    "文藝春秋漫画賞",
    "日本漫画家協会賞",
    "マンバ大賞",
    "ブロスコミックアワード",
    "みんなが選ぶTSUTAYAコミック大賞",
    "WEBマンガ総選挙",
    "電子コミック大賞",
    "次にくるマンガ大賞(コミックス部門)",
    "次にくるマンガ大賞(Webマンガ部門)",
    "文化庁メディア芸術祭マンガ部門",
    "このマンガを読め！"
  ];

  const autocompleteSearch = autocomplete({
    container: searchInputId,
    detachedMediaQuery: "none",
    placeholder: placeholders[Math.floor(Math.random() * placeholders.length)],
    id: searchInputName + "-autocomplete",
    getSources() {
      return [
        {
          sourceId: "boards",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: boardIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            header({ html }) {
              return html`
                <div class="">作品</div>
              `;
            },

            item({ item, components, html }) {
              return html`
                <div class="aa-manba-title-item">
                    <img src=${item.suggest_thumbnail_url} alt='${item.title}' />
                    <div>
                      ${components.Highlight({
                hit: item,
                attribute: "title",
                tagName: "mark"
              })}
                    </div>
                  </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              window.location.href = item.redirect_url;
            }
          }
        },
        {
          sourceId: "authors",
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: authorIndexName,
                  query,
                  params: {
                    filters: "is_suggestable: true",
                    hitsPerPage: 5
                  }
                }
              ]
            });
          },
          templates: {
            header({ html }) {
              return html`
                <div class="">著者</div>
              `;
            },
            item({ item, components, html }) {
              return html`
              <div class="aa-manba-author-item">
                <img src=${item.thumbnail_url} alt='${item.author_names_text}'　/>
                  <div>
                    ${components.Highlight({
                hit: item,
                attribute: "name",
                tagName: "mark"
              })}
                  </div>
                </div>
              `;
            }
          },
          onSelect({ insights, insightsEvents, item }) {
            if (item.redirect_url !== undefined) {
              window.location.href = item.redirect_url;
            }
          }
        }
      ];
    }
  });
}

function placeholderSearch(searchInput) {
  let targetName = searchInput + "-autocomplete-input";
  let element = document.querySelector(targetName);
  if (element === null) {
    return;
  }

  element.addEventListener("keypress", function (event) {
    let value = element.value;
    if (value === "" && event.key === "Enter") {
      event.preventDefault();
      let placeholderMessage = element.placeholder;
      let redirectUrl = `/search`;
      location.href = redirectUrl;
    }

    if (value !== "" && event.key === "Enter") {
      event.preventDefault();
      let redirectUrl = `/search?q=${element.value}`;
      location.href = redirectUrl;
    }
  });
}

function placeholderSearchFromButton(searchInput, searchButtonId) {
  let targetName = searchInput + "-autocomplete-input";
  let elementInput = document.querySelector(targetName);
  let elementButton = document.getElementById(searchButtonId);

  elementButton.addEventListener("click", function (event) {
    let value = elementInput.value;
    if (value === "") {
      event.preventDefault();
      let placeholderMessage = elementInput.placeholder;
      let redirectUrl = `/search`;
      location.href = redirectUrl;
    } else {
      let redirectUrl = `/search?q=${elementInput.value}`;
      location.href = redirectUrl;
    }
  });
}

document.addEventListener("DOMContentLoaded", function (event) {
  const desktopSearchInputName = "desktop-header-search";
  const desktopSearchInputNameId = "#" + desktopSearchInputName;
  const desktopSearchElement = document.getElementById(desktopSearchInputName);

  if (desktopSearchElement === null) {
    return;
  }

  if (document.getElementById(desktopSearchInputName) !== null) {
    enableAutoComplete(desktopSearchInputNameId, desktopSearchInputName);
  }

  placeholderSearch(desktopSearchInputNameId);

  const desktopSearchButtonId = "desktop-header-search-button";
  placeholderSearchFromButton(desktopSearchInputNameId, desktopSearchButtonId);

  const smartphoneSearchInputName = "smartphone-header-search";
  const smartphoneSearchInputNameId = "#" + smartphoneSearchInputName;

  if (document.getElementById(smartphoneSearchInputName) !== null) {
    enableAutoComplete(smartphoneSearchInputNameId, smartphoneSearchInputName);
  }
  placeholderSearch(smartphoneSearchInputNameId);
});
