document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let menuButton = document.querySelector(
    ".smartphone-header .smartphone-header-item-left .smartphone-header-item-menu"
  );
  let headerMenu = document.querySelector(".smartphone-header-menu");
  let menuCloseButton = document.querySelector(
    ".smartphone-header-menu #js-smartphone-header-close"
  );
  let header = document.querySelector("header");

  if (!menuButton || !headerMenu || !menuCloseButton || !header) {
    return;
  }

  menuButton.addEventListener("click", function (event) {
    event.preventDefault();
    window.scrollTo(0, 0);

    headerMenu.classList.add("open");
    menuButton.classList.add("close");
    header.classList.add("open");
  });

  menuCloseButton.addEventListener("click", function (event) {
    event.preventDefault();
    headerMenu.classList.remove("open");
    menuButton.classList.remove("close");
    header.classList.remove("open");
  });

  let lastScrollTop = 0;
  let ticking = false;

  function handleScroll() {
    const scrollTop = Math.ceil(document.documentElement.scrollTop);
    if (!ticking) {
      window.requestAnimationFrame(function () {
        if (scrollTop > lastScrollTop) {
          header.classList.add('hidden');
        } else {
          header.classList.remove('hidden');
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        ticking = false;
      });
      ticking = true;
    }
  }

  window.addEventListener('scroll', handleScroll);

});
