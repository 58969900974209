import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  const modules = document.querySelectorAll(".scroll-fade");

  if (!modules) {
    return;
  }

  let lastScroll = window.scrollY;
  let ticking = false;

  function handleScroll() {
    const currentScroll = Math.ceil(window.scrollY);
    if (!ticking) {
      window.requestAnimationFrame(function () {
        modules.forEach(module => {
          if (currentScroll > lastScroll) {
            module.classList.add('fade-out');
            module.classList.remove('fade-in');
          } else {
            module.classList.add('fade-in');
            module.classList.remove('fade-out');
          }
        });
        lastScroll = currentScroll <= 0 ? 0 : currentScroll;
        ticking = false;
      });

      ticking = true;
    }
  }

  let initState = () => {
    window.addEventListener('scroll', handleScroll);
  };

  pubsubStore.subscribe("userLoginCompleted", args => {
    initState();
  });

});
