import Api from "../Api";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let element = document.querySelector("#author-visited");
  if (element === null) {
    return;
  }

  let authorId = element.dataset.authorId;
  Api.addUserRecentVisitedAuthorLogs(authorId);

});
