document.addEventListener("DOMContentLoaded", function(event) {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let calcHeaderHeight = () => {
    return app.isSmartPhone() ? 55 : 70;
  };

  let scrollToHash = targetId => {
    const targetElement = document.querySelector(targetId);
    const targetOffsetTop =
      window.pageYOffset +
      targetElement.getBoundingClientRect().top -
      calcHeaderHeight();
    window.scrollTo({
      top: targetOffsetTop,
      behavior: "smooth"
    });
  };

  var urlHash = location.hash; //URLのハッシュタグを取得
  if (urlHash) {
    //ハッシュタグが有る
    setTimeout(function() {
      //無くてもいいが有ると動作が安定する
      scrollToHash(urlHash);
    }, 0);
  }

  anchorLinksArr.forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault();
      const targetId = link.hash;
      scrollToHash(targetId);
    });
  });
});
