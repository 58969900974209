import Api from "../Api";

document.addEventListener("DOMContentLoaded", function (event) {
  "use strict";

  let element = document.querySelector("#board-visited");
  if (element === null) {
    return;
  }

  let boardId = element.dataset.boardId;
  Api.addUserRecentVisitedBoardLogs(boardId);

});
