document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let modules = document.querySelectorAll(".want-to-recalls-comment-module");

  if (modules.length == 0) {
    return;
  }

  modules.forEach(element => {
    let resolvedCommentButton = element.getElementsByClassName(
      "resolved-comment-button"
    )[0];
    const resolvedCommentId = resolvedCommentButton.dataset.resolvedCommentId;
    let url = "/api/want_to_recall/resolved_comments/" + resolvedCommentId;

    return fetch(url, {
      method: "GET",
      credentials: "same-origin"
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        if (json.owned == true) {
          resolvedCommentButton.style.display = "block";
        }
      });
  });

  function checkResolved(modules) {
    for (let i = 0; i < modules.length; i++) {
      let element = modules[i];
      let resolvedCommentButton = element.getElementsByClassName(
          "resolved-comment-button"
      )[0];

      if (resolvedCommentButton.classList.contains("resolved")) {
        return true;
      }
    }
    return false;
  }

  const toggleResolvedComment = targetResolvedComment => {
    let execute = true;
    let hasResolved = checkResolved(modules);
    for (let i = 0; i < modules.length; i++) {
      let element = modules[i];
      let resolvedCommentButton = element.getElementsByClassName(
        "resolved-comment-button"
      )[0];
      const resolvedCommentId = resolvedCommentButton.dataset.resolvedCommentId;

      if (targetResolvedComment.dataset.resolvedCommentId === resolvedCommentId) {
        if (targetResolvedComment.classList.contains("resolved")) {
          const result = confirm("未解決にしますか？");
          if (result === false) {
            execute = false;
            return execute;
          }
          resolvedCommentButton.classList.remove("resolved");
        } else {
          if (hasResolved) {
            alert("解決済みコメントを未解決にしてください")
            execute = false;
            return execute;
          }
          const result = confirm("解決済みにしますか？");
          if (result === false) {
            execute = false;
            return execute;
          }
          resolvedCommentButton.classList.add("resolved");
        }
      }
    }

    return execute;
  };

  modules.forEach(element => {
    let resolvedCommentButton = element.getElementsByClassName(
      "resolved-comment-button"
    )[0];
    const resolvedCommentId = resolvedCommentButton.dataset.resolvedCommentId;
    let url = "/api/want_to_recall/resolved_comments/" + resolvedCommentId;

    resolvedCommentButton.addEventListener("click", function() {
      let res = toggleResolvedComment(resolvedCommentButton);
      if (res == false) {
        return;
      }

      return fetch(url, {
        method: "PATCH",
        credentials: "same-origin",
        body: JSON.stringify({
          id: resolvedCommentId
        })
      })
        .then(response => {
          return response.json();
        })
        .then(function(json) {
          if (json.resolved == true) {
            const resolvedTopic = document.getElementById(
              "js-want-to-recalls-resolved-topic"
            );
            const resolvedTopicId = resolvedTopic.dataset.topicId;
            const redirectUrl =
              "/want_to_recall/" + resolvedTopicId + "/resolved_thanks";
            location.href = redirectUrl;
          } else {
            location.reload();
          }
        });
    });
  });
});

window.addEventListener("pageshow", function(event) {
  const perfEntries = performance.getEntriesByType("navigation");
  perfEntries.forEach(function(pe) {
    switch (pe.type) {
      case "navigate":
        //console.log('通常のアクセス');
        break;
      case "reload":
        //console.log('更新によるアクセス');
        break;
      case "back_forward":
        location.reload();
        //console.log('戻るによるアクセス');
        break;
      case "prerender":
        //console.log('レンダリング前');
        break;
    }
  });
});
