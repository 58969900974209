import Api from "../Api";
import loginStateStore from "../login_state_store";
import pubsubStore from "../pubsub_store";

document.addEventListener("DOMContentLoaded", function(event) {
  "use strict";

  let element = document.querySelector("#topic-visited");
  if (element === null) {
    return;
  }

  let topicId = element.dataset.topicId;
  Api.addUserRecentVisitedTopicLogs(topicId);

});
